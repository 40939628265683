.header {
  position: relative;
  z-index: 30;
  width: 100%;
  height: 60px;
  background-color: transparent;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  .header-inner {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .logo {
    padding: 4px 20px 0px;
    background: #FFF;
    border-radius: 0 0 10px 10px;
    box-shadow: 0px 5px 8px 0 rgba(#000, 0.15);
    img {
      height: 76px;
    }
  }
}