/*
| -------------------------------------------------------------------
| Basic Layout Styles
| -------------------------------------------------------------------
*/
body {
  line-height: $line-height-base;
	color: $body-color;
	font-size: $font-size-base;
	font-family: $body-font;
	font-weight: 400;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	overflow-x: hidden;
	background: #FFF;
}
h1, h2, h3, h4, h5, h6 {
	font-family: $heading-font;
	color: $heading-color;
	font-weight: 700;
	line-height: 1;
  margin: 0 0 20px 0;
}
h1 {
  font-size: $font-size-h1
}
h2 {
  font-size: $font-size-h2
}
h3 {
  font-size: $font-size-h3
}
h4 {
  font-size: $font-size-h4
}
h5 {
  font-size: $font-size-h5
}
h6 {
  font-size: $font-size-h6
}

/*
| -------------------------------------------------------------------
| Layout Structure
| -------------------------------------------------------------------
*/

#root {
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr max-content;
	grid-template-areas:
		"header"
		"content"
		"footer";
	height: 100%;
	min-height: 100vh;
	overflow-x: hidden;
}
.header {
	grid-area: header;
}
.main-content {
	grid-area: content;
}
.footer {
	grid-area: footer;
}

/**/

.header,
.main-content,
.footer {
	display: grid;
	grid-template-columns: 1fr 1140px 1fr;
}

.header {
	.header-inner {
		grid-column: 2 / -2;
	}
}

.footer {
	.footer-inner {
		grid-column: 1 / -1;
	}
}

.main-content {
	grid-row: 1 / 3;
	> * {
		grid-column: 2 / -2;
	}
	.section-content {
		grid-column: 1 / -1;
		display: grid;
    grid-template-columns: 1fr 1140px 1fr;
    position: relative;
		.content-inner {
			grid-column: 2 / -2;
		}
		.swiper-container {
			grid-column: 1 / -1;
		}
	}
}

/**/

@media only screen and (max-width: 1280px) {
	.header,
  .main-content,
  .main-content .section-content,
  .footer {
		grid-template-columns: 20px 1fr 20px;
  }
}

@media only screen and (max-width: 768px) {
	h1 {
		font-size: 34px;
	}
	h2 {
		font-size: 28px;
	}
	h3 {
		font-size: 24px;
	}
	h4 {
		font-size: 20px;
	}
}

@media only screen and (max-width: 480px) {
	body {
		font-size: 14px;
	}
}