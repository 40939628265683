@font-face {
  font-family: "flypass";
  src:url("../../fonts/flypass-v1.1.0.eot");
  src:url("../../fonts/flypass-v1.1.0.eot?#iefix") format("embedded-opentype"),
  url("../../fonts/flypass-v1.1.0.woff") format("woff"),
  url("../../fonts/flypass-v1.1.0.ttf") format("truetype"),
  url("../../fonts/flypass-v1.1.0.svg#flypass") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "flypass" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "flypass" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-arrow-medium:before {
  content: "\62";
}
.icon-arrow-thin:before {
  content: "\63";
}
.icon-carril-medium:before {
  content: "\64";
}
.icon-carril-thin:before {
  content: "\65";
}
.icon-cerrar-medium:before {
  content: "\66";
}
.icon-cerrar-thin:before {
  content: "\67";
}
.icon-chat-medium:before {
  content: "\68";
}
.icon-chat-thin:before {
  content: "\69";
}
.icon-descubre-medium:before {
  content: "\6a";
}
.icon-descubre-thin:before {
  content: "\6b";
}
.icon-disfruta:before {
  content: "\6c";
}
.icon-icn-arrow-medium:before {
  content: "\6d";
}
.icon-icn-arrow-thin:before {
  content: "\6e";
}
.icon-icn-cerrar-medium:before {
  content: "\71";
}
.icon-icn-cerrar-thin:before {
  content: "\72";
}
.icon-icn-chat-medium:before {
  content: "\73";
}
.icon-icn-chat-thin:before {
  content: "\74";
}
.icon-icn-descubre-medium:before {
  content: "\75";
}
.icon-icn-descubre-thin:before {
  content: "\76";
}
.icon-icn-info-medium:before {
  content: "\78";
}
.icon-icn-info-thin:before {
  content: "\79";
}
.icon-icn-menu-medium:before {
  content: "\7a";
}
.icon-icn-menu-thin:before {
  content: "\41";
}
.icon-icn-pin-medium:before {
  content: "\42";
}
.icon-icn-pin-thin:before {
  content: "\43";
}
.icon-icn-play-medium:before {
  content: "\44";
}
.icon-icn-play-thin:before {
  content: "\45";
}
.icon-icn-precio-medium:before {
  content: "\46";
}
.icon-icn-precio-thin:before {
  content: "\47";
}
.icon-icn-tip-medium:before {
  content: "\48";
}
.icon-icn-tip-thin:before {
  content: "\49";
}
.icon-info-medium:before {
  content: "\4b";
}
.icon-info-thin:before {
  content: "\4c";
}
.icon-menu-medium:before {
  content: "\4d";
}
.icon-menu-thin:before {
  content: "\4e";
}
.icon-noche-despejado:before {
  content: "\4f";
}
.icon-nublado:before {
  content: "\50";
}
.icon-nublado-lluvia:before {
  content: "\51";
}
.icon-nublado-lluvialeve:before {
  content: "\52";
}
.icon-pacial-nubes-noche:before {
  content: "\53";
}
.icon-pacial-nubes-noche-lluvia:before {
  content: "\54";
}
.icon-pacial-nubes-noche-lluvialeve:before {
  content: "\55";
}
.icon-parcial-nubes:before {
  content: "\56";
}
.icon-parcial-nubes-lluvia:before {
  content: "\57";
}
.icon-parcial-nubes-lluvialeve:before {
  content: "\58";
}
.icon-pin-medium:before {
  content: "\59";
}
.icon-pin-thin:before {
  content: "\5a";
}
.icon-play-medium:before {
  content: "\30";
}
.icon-play-thin:before {
  content: "\31";
}
.icon-precio-medium:before {
  content: "\32";
}
.icon-precio-thin:before {
  content: "\33";
}
.icon-recarga:before {
  content: "\34";
}
.icon-registrate:before {
  content: "\35";
}
.icon-soleado:before {
  content: "\36";
}
.icon-tip-medium:before {
  content: "\37";
}
.icon-tip-thin:before {
  content: "\38";
}
.icon-registro-medium:before {
  content: "\39";
}
.icon-registro-thin:before {
  content: "\21";
}
.icon-icn-registro-mediun:before {
  content: "\22";
}
.icon-icn-registro-thin:before {
  content: "\23";
}
.icon-agilidad:before {
  content: "\24";
}
.icon-control-de-gastos:before {
  content: "\25";
}
.icon-deducible:before {
  content: "\26";
}
.icon-fraude:before {
  content: "\27";
}
.icon-evita-fraude:before {
  content: "\28";
}
.icon-formas-de-pago:before {
  content: "\29";
}
.icon-recarga-auto:before {
  content: "\2b";
}
.icon-usuario-thin:before {
  content: "\2c";
}
.icon-usuario-medium:before {
  content: "\2d";
}
.icon-icn-usuario-medium:before {
  content: "\2e";
}
.icon-icn-usuario-thin:before {
  content: "\2f";
}
.icon-icn-facturas-thin:before {
  content: "\3a";
}
.icon-icn-facturas-medium:before {
  content: "\3b";
}
.icon-facturas-thin:before {
  content: "\3c";
}
.icon-facturas-medium:before {
  content: "\3d";
}
.icon-icn-notificacion-thin:before {
  content: "\3e";
}
.icon-icn-notificacion-medium:before {
  content: "\3f";
}
.icon-icn-ayuda-thin:before {
  content: "\40";
}
.icon-icn-ayuda-medium:before {
  content: "\5b";
}
.icon-icn-reportes-medium:before {
  content: "\5d";
}
.icon-icn-reportes-thin:before {
  content: "\5e";
}
.icon-icn-salir-medium:before {
  content: "\5f";
}
.icon-icn-salir-thin:before {
  content: "\60";
}
.icon-icn-seguridad-medium:before {
  content: "\7b";
}
.icon-icn-seguridad-thin:before {
  content: "\7c";
}
.icon-notificacion-thin:before {
  content: "\7d";
}
.icon-reportes-medium:before {
  content: "\7e";
}
.icon-reportes-thin:before {
  content: "\5c";
}
.icon-salir-medium:before {
  content: "\e000";
}
.icon-salir-thin:before {
  content: "\e001";
}
.icon-seguridad-medium:before {
  content: "\e002";
}
.icon-seguridad-thin:before {
  content: "\e003";
}
.icon-notificacion-medium:before {
  content: "\e004";
}
.icon-auto:before {
  content: "\e005";
}
.icon-ayuda-medium:before {
  content: "\e006";
}
.icon-ayuda-thin:before {
  content: "\e007";
}
.icon-bus:before {
  content: "\e008";
}
.icon-camion:before {
  content: "\e009";
}
.icon-descarga:before {
  content: "\e00a";
}
.icon-hoteles:before {
  content: "\e00c";
}
.icon-restaurantes:before {
  content: "\e00d";
}
.icon-salud:before {
  content: "\e00e";
}
.icon-icn-carril-thin:before {
  content: "\6f";
}
.icon-icn-carril-medium:before {
  content: "\70";
}
.icon-vehiculos:before {
  content: "\e00f";
}
.icon-mi-cuenta:before {
  content: "\e010";
}
.icon-mi-cuenta-2:before {
  content: "\e011";
}
.icon-registro:before {
  content: "\e012";
}
.icon-registro-empresa:before {
  content: "\e013";
}
.icon-pago:before {
  content: "\e014";
}
.icon-pago-frecuente:before {
  content: "\e015";
}
.icon-pasa-paga:before {
  content: "\e016";
}
.icon-pasa-paga-2:before {
  content: "\e017";
}
.icon-notificacion:before {
  content: "\e018";
}
.icon-recarga-tu-cuenta-2:before {
  content: "\e019";
}
.icon-recarga-tu-cuenta:before {
  content: "\e01a";
}
.icon-recarga-multiple-2:before {
  content: "\e01b";
}
.icon-recarga-multiple:before {
  content: "\e01c";
}
.icon-instagram:before {
  content: "\61";
}
.icon-twitter:before {
  content: "\77";
}
.icon-facebook:before {
  content: "\4a";
}
.icon-activa:before {
  content: "\e01f";
}
.icon-para-todos:before {
  content: "\2a";
}
.icon-abajo:before {
  content: "\e020";
}
.icon-activar-medio-de-pago:before {
  content: "\e021";
}
.icon-activar-medio-de-pago-2:before {
  content: "\e022";
}
.icon-activar-vehiculo:before {
  content: "\e023";
}
.icon-adelante:before {
  content: "\e024";
}
.icon-advertencia:before {
  content: "\e025";
}
.icon-agregar:before {
  content: "\e026";
}
.icon-agregar-pago:before {
  content: "\e027";
}
.icon-agregar-pago2:before {
  content: "\e028";
}
.icon-agregar-tarjeta:before {
  content: "\e029";
}
.icon-agregar-vehiculo:before {
  content: "\e02a";
}
.icon-anular-compra:before {
  content: "\e02b";
}
.icon-anular-compra2:before {
  content: "\e02c";
}
.icon-arriba:before {
  content: "\e02d";
}
.icon-asociar-cajas:before {
  content: "\e02e";
}
.icon-atras:before {
  content: "\e02f";
}
.icon-auto-1:before {
  content: "\e030";
}
.icon-ayuda:before {
  content: "\e031";
}
.icon-ayuda-2:before {
  content: "\e032";
}
.icon-billetera:before {
  content: "\e033";
}
.icon-bus-1:before {
  content: "\e034";
}
.icon-buscar:before {
  content: "\e035";
}
.icon-cambio-clave:before {
  content: "\e036";
}
.icon-camion-1:before {
  content: "\e037";
}
.icon-cerrar-sesion:before {
  content: "\e038";
}
.icon-check:before {
  content: "\e039";
}
.icon-check2:before {
  content: "\e03a";
}
.icon-clave:before {
  content: "\e03b";
}
.icon-comprar-tag:before {
  content: "\e03c";
}
.icon-configurar-factura:before {
  content: "\e03d";
}
.icon-consultar-pedido:before {
  content: "\e03e";
}
.icon-consultar-pedido2:before {
  content: "\e03f";
}
.icon-consultar-pedido3:before {
  content: "\e040";
}
.icon-contacto:before {
  content: "\e041";
}
.icon-contactos:before {
  content: "\e042";
}
.icon-crear-factura:before {
  content: "\e043";
}
.icon-crear-pedido:before {
  content: "\e044";
}
.icon-crear-pedido2:before {
  content: "\e045";
}
.icon-desactivar-medio-de-pago:before {
  content: "\e046";
}
.icon-desactivar-medio-de-pago-2:before {
  content: "\e047";
}
.icon-desactivar-vehiculo:before {
  content: "\e048";
}
.icon-descarga-pdf:before {
  content: "\e049";
}
.icon-editar:before {
  content: "\e04a";
}
.icon-eliminar:before {
  content: "\e04b";
}
.icon-eliminar-pago:before {
  content: "\e04c";
}
.icon-eliminar-pago2:before {
  content: "\e04d";
}
.icon-eliminar-tarjeta:before {
  content: "\e04e";
}
.icon-eliminar-vehiculo:before {
  content: "\e04f";
}
.icon-email:before {
  content: "\e050";
}
.icon-entrada-dinero:before {
  content: "\e051";
}
.icon-error:before {
  content: "\e052";
}
.icon-exportar:before {
  content: "\e053";
}
.icon-factura:before {
  content: "\e054";
}
.icon-formas-de-pago-1:before {
  content: "\e055";
}
.icon-generar-cajas:before {
  content: "\e056";
}
.icon-generar-tags:before {
  content: "\e057";
}
.icon-info:before {
  content: "\e05a";
}
.icon-info-2:before {
  content: "\e05b";
}
.icon-limpiar:before,
.icon-limpiar-1:before {
  content: "\e05c";
}
.icon-limpiar-2:before {
  content: "\e05d";
}
.icon-limpiar-3:before {
  content: "\e05e";
}
.icon-limpiar-4:before {
  content: "\e05f";
}
.icon-lista:before {
  content: "\e060";
}
.icon-lista-2:before {
  content: "\e061";
}
.icon-lista-3:before {
  content: "\e062";
}
.icon-mas:before {
  content: "\e063";
}
.icon-menu:before {
  content: "\e064";
}
.icon-mi-cuenta-1:before {
  content: "\e065";
}
.icon-mi-cuenta-2-1:before {
  content: "\e066";
}
.icon-notificacion-1:before {
  content: "\e067";
}
.icon-pago-1:before {
  content: "\e068";
}
.icon-pago-frecuente-1:before {
  content: "\e069";
}
.icon-pasa-paga-1:before {
  content: "\e06a";
}
.icon-pasa-paga-2-1:before {
  content: "\e06b";
}
.icon-pdf:before {
  content: "\e06c";
}
.icon-peaje:before {
  content: "\e06d";
}
.icon-pin:before {
  content: "\e06e";
}
.icon-promedio-consumo:before {
  content: "\e06f";
}
.icon-qr:before {
  content: "\e070";
}
.icon-recarga-1:before {
  content: "\e071";
}
.icon-recarga-2:before {
  content: "\e072";
}
.icon-recarga-multiple-1:before {
  content: "\e073";
}
.icon-recarga-multiple-2-1:before {
  content: "\e074";
}
.icon-recarga-tu-cuenta-1:before {
  content: "\e075";
}
.icon-recarga-tu-cuenta-2-1:before {
  content: "\e076";
}
.icon-registro-1:before {
  content: "\e077";
}
.icon-registro-empresa-1:before {
  content: "\e078";
}
.icon-reporte:before {
  content: "\e079";
}
.icon-saldo-bajo:before {
  content: "\e07a";
}
.icon-saldo-medio:before {
  content: "\e07b";
}
.icon-saldo-ok:before {
  content: "\e07c";
}
.icon-salida-dinero:before {
  content: "\e07d";
}
.icon-seguridad:before {
  content: "\e07e";
}
.icon-tag:before {
  content: "\e07f";
}
.icon-tarjeta:before {
  content: "\e080";
}
.icon-transito:before {
  content: "\e081";
}
.icon-usuario:before {
  content: "\e082";
}
.icon-usuario-2:before {
  content: "\e083";
}
.icon-vehiculos-1:before {
  content: "\e084";
}
.icon-vehiculos-2:before {
  content: "\e085";
}
.icon-vehiculos-3:before {
  content: "\e086";
}
.icon-ver-mas:before {
  content: "\e087";
}
.icon-ver-mas-3:before {
  content: "\e088";
}
.icon-ver-pdf:before {
  content: "\e089";
}
.icon-diligenciar:before {
  content: "\e058";
}
.icon-home:before,
.icon-inicio:before {
  content: "\e00b";
}
.icon-parqueadero:before {
  content: "\e01d";
}
.icon-peaje-1:before {
  content: "\e01e";
}
.icon-soat:before {
  content: "\e059";
}