/*
| -------------------------------------------------------------------
| Base Colors
| -------------------------------------------------------------------
*/
$primary-color: #C2D501 !default;
$body-color: #535350 !default;
$heading-color: #328252 !default;
//$heading-color: #2C7F4E !default;


/*
| -------------------------------------------------------------------
| Line Height Variables
| -------------------------------------------------------------------
*/
$line-height-base: 1.4;
$line-height-content: 1.5;


/*
| -------------------------------------------------------------------
| Font Families
| -------------------------------------------------------------------
*/
$body-font: 'Nunito', sans-serif;
$heading-font: 'Montserrat', sans-serif;

/*
| -------------------------------------------------------------------
| Font Sizes
| -------------------------------------------------------------------
*/
$font-size-base: 16px !default;
$font-size-h1: 40px !default;
$font-size-h2: 34px !default;
$font-size-h3: 26px !default;
$font-size-h4: 22px !default;
$font-size-h5: 20px !default;
$font-size-h6: 16px !default;