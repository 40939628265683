.hero {
  width: 100%;
  height: 620px;
  background-position: center;
  background-size: cover;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    background: rgba(#0C283D, 0.4);
  }
  .hero-content {
    position: relative;
    z-index: 20;
    display: flex;
    align-items: center;
    height: 100%;
    .hero-content-info {
      width: 60%;
      padding: 0 40px 0 0;
      text-align: left;
      color: #FFF;
      h1 {
        color: #FFF;
        font-size: 46px;
      }
      p {
        font-size: 20px;
        line-height: 1.1;
      }
    }
    .hero-content-form {
      width: 40%;
    }
  }
  //
  @media only screen and (max-width: 599px) {
    .hero-content .hero-content-info h1 {
      font-size: 24px;
      line-height: 1.3;
    }
  }
}

.flyp-card {
  width: fit-content;
  background: #FFF;
  padding: 20px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0px 5px 8px 0 rgba(#000, 0.15);
  h2 {
    margin-bottom: 15px;
    font-size: 26px;
  }
  p {
    font-size: 12px;
    color: #888;
    margin-bottom: 15px;
  }
  li {
    color: #888;
  }
  hr {
    height: 1px;
    margin: 25px 0;
    border: none;
    flex-shrink: 0;
    background-color: rgba(#000, 0.12);
  }
}

.form-flyp-paseya {
  display: flex;
  flex-direction: column;
  a {
    font-size: 12px;
    color: $heading-color;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: $primary-color;
    }
  }
}

.advantages {
  background: #F6F7ED;
  padding: 0 0 50px;
  .shapeCurveUp {
    display: block;
    position: absolute;
    top: -100px;
    left: 0;
    path {
      fill: #F6F7ED;
      stroke: #F6F7ED;
    }
    &.scu-before {
      top: -115px;
      height: 115px;
      path {
        fill: rgba(#F6F7ED, 0.5);
        stroke: transparent;
      }
    }
  }
  .content-inner {
    text-align: center;
  }
  .logoFlypass {
    height: 110px;
    margin-bottom: 10px;
  }
  .icon {
    font-size: 120px;
    height: 120px;
    line-height: 1;
    display: inline-block;
    color: #7f8762;
  }
  h4 {
    line-height: 1.4;
  }
  h2 {
    text-align: center;
    margin-bottom: 30px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li {
      // width: 33.2%;
      width: 49%;
      padding: 30px;
    }
  }
}

.items {
  display: flex;
  margin-bottom: 50px;
  .item {
    display: flex;
    align-items: center;
    width: 30%;
    background-color: rgba(#fff, 0.3);
    border-radius: 10px;
    box-shadow: 0px 5px 8px 0 rgba(#000, 0.15);
    margin: 10px;
    padding: 20px;
    font-size: 14px;
  }
  //
  @media only screen and (max-width: 800px) {
    flex-wrap: wrap;
    .item {
      width: 100%;
    }
  }
}

.comingSoon,
.accepted {
  text-align: center;
  h5 {
    margin: 0;
  }
  .icon {
    color: $heading-color;
    font-size: 140px;
    height: 140px;
    line-height: 1;
    display: inline-block;
  }
  p {
    font-size: 16px;
  }
}
.accepted {
  .icon {
    font-size: 120px;
    height: 120px;
    margin-top: -15px;
    margin-bottom: 20px;
  }
}

.register {
  background: #F6F7ED;
  color: #666;
  padding: 16px 12px 20px;
  border-radius: 10px;
  margin-bottom: 25px;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
  p {
    color: #666;
  }
  .buttons {
    display: flex;
    justify-content: center;
    a {
      margin: 0 10px;
    }
  }
}

.alert {
  padding: 10px;
  font-size: 12px;
  color: #FFF;
  border-radius: 6px;
  background-color: #d32f2f;
  display: flex;
  align-items: center;
  margin: 10px 0 20px;
  .material-icons {
    margin-right: 6px;
    color: #FFF;
  }
}

.isLoading {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 200;
  left: 0;
  top: 0;
  background: rgba(#000, 0.8);
  img {
    position: fixed;
    top: 50vh;
    transform: translateY(-50%)
  }
}

/**/

@media only screen and (max-width: 768px) {
  .flyp-card {
    background: rgba(#FFF, 0.9);
  }
  .hero {
    height: auto;
    padding-bottom: 100px;
    .hero-content {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding-top: 110px;
      .hero-content-info {
        width: 100%;
        padding: 0;
        text-align: center;
      }
      .hero-content-form {
        width: 100%;
      }
    }
  }
  .advantages ul li {
    width: 50%;
  }
}

@media only screen and (max-width: 599px) {
  .advantages {
    .icon {
      font-size: 80px;
      height: 80px;
    }
    h4 {
      font-size: 16px;
    }
    ul li {
      padding: 12px;
      width: 100%;
    }
  }
}

.flyp-button {
  background-color: $primary-color;
  box-shadow: 0 0 0 4px rgba($primary-color, 0.25);
  color: $heading-color;
  letter-spacing: -0.4px;
  border-radius: 10px;
  font: 600 17px Montserrat, sans-serif;
  padding: 16px 40px 15px;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: $primary-color;
    background-color: $heading-color;
    box-shadow: 0 0 0 4px rgba($heading-color, 0.25);
  }
}