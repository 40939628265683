.MuiFormControl-marginNormal-2 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
button {
  text-transform: none !important;
}
.linkBtn {
  text-transform: none !important;
  border-radius: 8px !important;
}