*, *:after, *:before {
  box-sizing: border-box;
}
::selection {
	background: $primary-color;
	color: #FFF;
  text-shadow: none;
}
::-moz-selection {
	background: $primary-color; /* Firefox */
	color: #FFF;
  text-shadow: none;
}
::-webkit-selection {
	background: $primary-color; /* Safari */
	color: #FFF;
	text-shadow: none;
}
:active, :focus {
  outline: none !important;
}

::-moz-placeholder {
  opacity: 1;
}
::placeholder {
  color: #979797;
}

button {
  border: 0;
  cursor: pointer;
}
figure {
  margin: 0;
}
img {
  border-style: none;
  max-width: 100%;
}
svg:not(:root) {
  overflow: hidden;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
audio,
video {
  display: inline-block;
}

b,
strong {
  font-weight: bolder;
}

a {
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
  text-decoration: none;
  color: $heading-color;
}

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

body {
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}
nav {
	ul {
		list-style: none;
		margin: 0;
    padding: 0;
  }
}
p {
  margin: 0 0 30px 0;
}