.footer {
  background: #0A2525;
  color: #BEBFA2;
  padding: 35px 0;
  position: relative;
  min-height: 180px;
  &:before {
    background: url(../../images/flypass-icon.svg) center bottom no-repeat;
    position: absolute;
    content: "";
    z-index: 1;
    opacity: .2;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
  }
  .footer-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}